import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Karta from "../components/Karta";
import KartaRichText from "../components/KartaRichText";
import SEO from "../components/SEO";
import BanerPageLayout from "../layout/BanerPageLayout";
import PageTemplate from "../layout/DefaultLayout";

export default ({ data }: any) => {
  return (
    <BanerPageLayout baner={data.contentfulStronaOferta.baner}>
      <SEO {...data.contentfulStronaOferta} />

      <KartaRichText content={data.contentfulStronaOferta.tresc} />
    </BanerPageLayout>
  );
};

export const data = graphql`
  query ($id: String) {
    contentfulStronaOferta(id: { eq: $id }) {
      seoTytulKarty
      seoOpisKarty
      baner {
        gatsbyImageData
        description
        contentful_id
        __typename
      }
      tresc {
        raw
        references {
          gatsbyImageData
          description
          contentful_id
          __typename
        }
      }
    }
  }
`;
